import { API_URL } from '../../../app-config/constants';
import { get, post, remove } from '../request';
const BASE_URL = `${API_URL}/summary-assistant`;
getPromptResult.operation = 'COMMAND';
export function getPromptResult(id, type, options) {
    return post(BASE_URL, { postData: Object.assign({ type, id }, options) });
}
getSummary.operation = 'READ';
export function getSummary(id) {
    return get(`${BASE_URL}/${id}`);
}
deleteKeyTakeaway.operation = 'COMMAND';
export function deleteKeyTakeaway(id, keyTakeawayId) {
    return remove(`${BASE_URL}/${id}/${keyTakeawayId}`);
}
deleteAllKeyTakeaways.operation = 'COMMAND';
export function deleteAllKeyTakeaways(id) {
    return remove(`${BASE_URL}/${id}/delete-all`);
}
export function getQuotaTokens() {
    return get(`${BASE_URL}/tokens`);
}
